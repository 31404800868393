export default function (seo) {
  if (!seo) {
    return;
  }

  const img = seo.metaImage?.formats.thumbnail ?? {};

  seo.metaTitle =
    seo.metaTitle ??
    'Los Naaimachines: Specialist in Naaimachines en Toebehoren';
  seo.metaDescription =
    seo.metaDescription ??
    'Ontdek ons assortiment aan kwalitatieve naaimachines, onderdelen, en accessoires. Voor zowel beginners als professionals, wij zijn dé expert op naaigebied.';

  useSeoMeta({
    title: seo.metaTitle,
    description: seo.metaDescription,
    ogTitle: seo.metaTitle,
    ogDescription: seo.metaDescription,
    ogImageUrl: img.url,
    ogImageType: img.mime,
    ogImageWidth: img.width,
    ogImageHeight: img.height,
  });
}
